
import { NuxtVueI18n } from 'nuxt-i18n'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapState } from 'vuex'
import { from_utf8_bytes } from '~/modules/utility/utils';

@Component({        
    computed: {},
})
export default class MainPage extends Vue {
    showList:boolean = false;
    timer:any = null;

    toggleList() {        
        //@ts-ignore
        ym_goal('change_lang')

        this.showList = !this.showList;
        if(this.showList)
            this.resetTimer();
    }

    /** Specifying "Русский" directly in nuxt.config.ts breaks encoding when building statically */
    lang_name( locale : any ) {
        if ( locale.code == 'ru' ) {
            //Русский:
            return from_utf8_bytes( [208, 160, 209, 131, 209, 129, 209, 129, 208, 186, 208, 184, 208, 185 ] )
        }
        //console.log( 'Русский:', new TextEncoder().encode( 'Русский' ) )
        return locale.name
    }
    
    get localeCode3() { 
        let locale:any = this.localeCurrent
        const r = locale.code3
        if ( r.toLowerCase() == 'rus' ) {
            //рус:
            return from_utf8_bytes( [209, 128, 209, 131, 209, 129] )
        }
        return locale.code3
    }

    get localeCurrent() {    
        return this.localesAll.find( i => i.code == this.$i18n.locale ) ;                  
    }

    get localesAll() {
        //only ru and en are properly implemented for now:
        const supported = [ 'ru', 'en' ]
        return ( <NuxtVueI18n.Options.LocaleObject[]>this.$i18n.locales ).filter( l => supported.includes( l.code ) )
    }

    get localesOther() {
        return this.localesAll.filter((i) => i.code !== this.$i18n.locale)
    }

    resetTimer() {
        if(this.timer) {
            clearTimeout(this.timer)
            this.timer = null;
        }

        this.timer = setTimeout( ()=>this.showList = false, 3000)
    }    

    setLanguage(language: string) {        
        let i18n = this.$i18n;
        if(!i18n) 
            return;

        this.resetTimer();
        
        console.debug("Set locale |"+ language+"|");
        i18n.setLocale(language);                
        i18n.setLocaleCookie(language);                   
        //this.$router.push(this.switchLocalePath(language)); 

        this.toggleList();
    }
}
