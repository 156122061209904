// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/ui/phoenix/menu_normal_en.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/ui/phoenix/menu_hover_en.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/images/ui/phoenix/menu_pressed_en.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../assets/images/ui/phoenix/menu_normal_ru.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../../assets/images/ui/phoenix/menu_hover_ru.png");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../../assets/images/ui/phoenix/menu_pressed_ru.png");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../../../assets/images/ui/phoenix/menu_normal_tg.png");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../../../assets/images/ui/phoenix/menu_hover_tg.png");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../../../assets/images/ui/phoenix/menu_pressed_tg.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".need_load[data-v-2a0b7b2a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");width:0}.need_load_ru[data-v-2a0b7b2a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ")}.need_load_tg[data-v-2a0b7b2a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + "),url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ")}.back_button[data-v-2a0b7b2a]{background-color:transparent;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:contain;height:5em;left:.4em;position:absolute;top:0;width:10em}.back_button[data-v-2a0b7b2a]:hover{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.back_button[data-v-2a0b7b2a]:active{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.back_button_ru[data-v-2a0b7b2a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.back_button_ru[data-v-2a0b7b2a]:hover{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}.back_button_ru[data-v-2a0b7b2a]:active{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ")}.back_button_tg[data-v-2a0b7b2a]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ")}.back_button_tg[data-v-2a0b7b2a]:hover{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ")}.back_button_tg[data-v-2a0b7b2a]:active{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
