
import Vue from 'vue'
import { Prop, Watch } from "vue-property-decorator";
import { Route } from 'vue-router';
export default Vue.extend({

    props:{
        digital: {            
            default: ""
        },
        timer: {
            default: ""
        },
        played: {
            default: ""
        }
    },

    data: () => {
        return {
            //rebrand:
            animate: false,

            stopped: false
        }
    },

    watch:{
        '$route' (to, from) {
            //console.log("change route", from, to)

            //rebrand:
            //this.animate = to.path === '/';
        }
    },

    mounted: function () {
        //rebrand:
        //this.animate = this.$route.path === "/";
    }
})
