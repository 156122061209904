

import { Vue, Component, Prop , Watch} from 'vue-property-decorator'
import {mapState} from "vuex"
import TradeOffer from '~/modules/model/Types/TradeOffer';
import Tournament from '~/modules/model/Types/Tournament';
import { starts_in, print_timestamp } from '~/modules/utility/utils';
import Player from '~/modules/model/Types/Player'
import { sharedStorageLocal } from "~/modules/SharedStorage";
//import { Route } from 'vue-router';

//@Watch('$route', { immediate: true, deep: true })
@Component({
    data: () => ({
        gift: null,
        needGoShop: false,
        intervalTimer: null,
        timeToTour: '',
        playedTournament: false 
    }),
    computed: {
        ...mapState("top", {
            topCreditsHash: "topCreditsHash",
            topRatingsHash: "topRatingsHash",
            topTournamentsHash: "topTournamentsHash",

            topCreditsHashLast: "topCreditsHashLast",    
            topTournamentsHashLast: "topTournamentsHashLast", 
            topRatingsHashLast: "topRatingsHashLast"
        }), 

        ...mapState( "trade" , {
            tradeNotify: "notifyCount",
            tradesGift: "tradesGiftAvailable"
        }),

        ...mapState( "game" , {
            gameNotify: "countJoinable"
        }),

        ...mapState( {
            allowLogout: "allowLogout",
            loggedIn: 'loggedIn',
        }),
        ...mapState(
            "tournament",
            {
                notifyCountTournaments: "notifyOpenCount",
                tournamentsOpen : "tournamentsOpen",
                tournamentsInPlay: "tournamentsInPlay"
            },
        ),
        ...mapState( {
            player: "player"
        } ),
        playerInPlayTournament() {
            //@ts-ignore
            return this.tournamentsInPlay
                    //@ts-ignore
                    .filter((t:Tournament) => t.isPlayerPlayTournament(this.player.uuid))
                    .sort((a: Tournament, b:Tournament) => {return a.start_timestamp - b.start_timestamp})
        },
        playerOpenTournament() {
            //@ts-ignore
            return this.tournamentsOpen
                    //@ts-ignore
                    .filter((t:Tournament) => t.isPlayerInPlay(this.player.uuid))
                    .sort((a: Tournament, b:Tournament) => {return a.start_timestamp - b.start_timestamp})
        }
    },
})

export default class LayoutInterface extends Vue {

    declare gameNotify:number

    declare tradeNotify:number;
    declare allowLogout:boolean;
    declare loggedIn:boolean;
    declare tradesGift: TradeOffer[];
    declare gift: TradeOffer | null;
    declare needGoShop:boolean;
    declare tournamentsOpen: Tournament[];
    declare playerOpenTournament: Tournament[];
    declare tournamentsInPlay: Tournament[];
    declare playerInPlayTournament: Tournament[];
    declare notifyCountTournaments:number;
    declare player:Player
    declare intervalTimer: any
    declare timeToTour: any
    declare playedTournament: boolean

    helpIsVisit:boolean = true;

    declare topCreditsHash: string;
    declare topRatingsHash: string;
    declare topTournamentsHash: string;

    declare topCreditsHashLast: string;   
    declare topTournamentsHashLast: string;
    declare topRatingsHashLast: string;

    mounted() {
        console.log( 'The whole game is considered as fully loaded and ready for playing.')
        //@ts-ignore
        ym_once('end_load')

        /*if (this.$route.path === "/") {
            this.go_play();
        }*/

        this.startTimer();
    }

    get playerVerified() {
        if (!this.player) {
            return false
        }
        return this.player.is_verified_email
    }

    get countTops() {
        let digit_value:number = (
            (this.topCreditsHash !== this.topCreditsHashLast?1:0)+
            (this.topRatingsHash !== this.topRatingsHashLast?1:0)+
            (this.topTournamentsHash !== this.topTournamentsHashLast?1:0)
        );
        return digit_value>0?digit_value:'';
    }

    destroyed() {
        if (this.intervalTimer) {
            clearInterval(this.intervalTimer);
        }
    }

    startTimer() {
        this.intervalTimer = setInterval(() => {
            this.timeToTour = this.getTimeToTour();
        }, 1000)
    }

    get isPhoenixProject() {
        return process.env.PHOENIX_GAME=="1"
    }

    get isStarzProject() {
        return process.env.STARZ_GAME=="1"
    }

    get backUrl() {
        let url = new URL(window.location.href);
        let backUrl = url.searchParams.get("back_url");
        if (backUrl) {
            return decodeURIComponent(backUrl)
        }
        return null
    }

    goToBackUrl() {
        let toUrl = this.backUrl;
        if (toUrl) {
            window.location.replace(toUrl)
        }
    }

    getTimeToTour() {
        if (this.player) {
            let inPlay:Tournament[] = this.playerInPlayTournament
            if (inPlay.length > 0) {
                this.playedTournament = inPlay[0].next_timestamp ? true : false;
                let timestamp = inPlay[0].next_timestamp ? inPlay[0].next_timestamp : inPlay[0].start_timestamp
                return starts_in(timestamp, this.$tc("DaysShorthand"))
            }
            this.playedTournament = false;
            let open:Tournament[] = this.playerOpenTournament
            if (open.length > 0) {
                return starts_in(open[0].start_timestamp, this.$tc("DaysShorthand"))
            }
            return '';
        }
        else {
            return '';
        }
    }

    get gameAndTournamentNotify() {
        return this.gameNotify + this.notifyCountTournaments;
    }

    doLogout() {
        //console.log("Logout please");
        this.$store.dispatch( 'doLogout' )
    }

    goMsg() {
       this.$router.push(this.localePath('/news'))
    }
    
    go_settings() {
        //@ts-ignore
        ym_goal('open_settings')

        this.$router.push(this.localePath('/settings'))
    }
    go_shop() {
        //@ts-ignore
        ym_goal('open_shop')
        this.$router.push(this.localePath('/shop'))
    }
    go_help() {
        //@ts-ignore
        ym_goal('open_info')

        sharedStorageLocal.set( "help_visited", true );
        this.helpIsVisit = true;
        this.$router.push( this.localePath('/pageHelp'))
    }
    go_play() {
        //@ts-ignore
        ym_goal('open_play')

        this.$router.push( this.localePath('/gameStart') )
    }
    go_tops() {
        //@ts-ignore
        ym_goal('open_tops')
        this.$router.push(this.localePath('/tops'))
    }
}
