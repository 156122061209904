import { render, staticRenderFns } from "./ButtonGame.vue?vue&type=template&id=784ebc0f&scoped=true"
import script from "./ButtonGame.vue?vue&type=script&lang=ts"
export * from "./ButtonGame.vue?vue&type=script&lang=ts"
import style0 from "./ButtonGame.vue?vue&type=style&index=0&id=784ebc0f&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "784ebc0f",
  null
  
)

export default component.exports