

const ISMAIL = process.env.MAILRU_GAME ? true : false;
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { spine_animation } from '~/modules/utility/utils';
import { isMobile } from 'mobile-device-detect';
@Component({
    data: () => ({
        isMail: ISMAIL
    })    
})
export default class LayoutBackground extends Vue {
    //isMail: boolean = ISMAIL
    mounted() {
        console.log( 'LOCALE:', this.$i18n.locale )

        //back to simple image:
        //this.init_spine()
    }

    @Watch('$i18n.locale')
    on_locale_change( new_val : any, old_val : any ) {
        this.apply_lang( new_val )
    }
    apply_lang( lang : string ) {
        if ( this.animation_locale ) {
            this.animation_locale(
                'animation_'
                +
                ( ( lang == 'ru' ) ? 'rus' : 'eng' )
            )
        }
    }
    init_spine() {
        const canvas = this.$refs.spineContainer;
        if ( ! canvas ) {
            console.error( 'Canvas must exist to init spine animation.' )
            return
        }
        this.animation_locale = spine_animation(
            canvas,
            'dynamic_background/load_ru.json',
            'dynamic_background/load_ru.atlas',
            'animation_eng',
        )
        this.apply_lang( this.$i18n.locale )
    }
    animation_locale : ( ( a : string ) => any ) | undefined = undefined

    get isNY24 () {
        const current_time = (new Date()).getTime();
        return (
            current_time > 1701723600000 && //(new Date(2023, 11, 5, 0, 0, 0, 0)).getTime()
            current_time < 1705266000000  //(new Date(2024, 0, 15, 0, 0, 0, 0)).getTime()
        );
    }
    get bgClasses () {
        const result:string[] = [ 'main_body' ];
        if (isMobile)
            result.push('main_body_mobile');
        if (this.isNY24)
            result.push('main_body_ny24');
        return result;
    }
}
