// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/ui/layout/game.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/ui/tournament/sword.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/images/ui/layout/game_ru.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".btn_game[data-v-784ebc0f]{animation-fill-mode:both;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");display:block;margin:auto;overflow:visible;position:absolute;top:43.750675164740194%;transform:scale(1)}.sword_img[data-v-784ebc0f]{background-color:transparent;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat;background-size:contain;height:1.28em;line-height:1.28em;margin-right:.3em;margin-top:.1em;width:1.28em}.btn_timer[data-v-784ebc0f]{background-color:#00f;border-radius:1.3333em;box-shadow:0 .1666em .1111em 0 rgba(0,0,0,.5);box-sizing:border-box;color:#fff;font-size:1.6em;height:1.8em;line-height:1.8em;min-width:1.8em;opacity:1;overflow:hidden;padding:0 .8em;pointer-events:none;position:absolute;right:2.1em;text-align:center;top:0;transition:opacity .3s ease-out}.btn_timer_orange[data-v-784ebc0f]{background-color:#ff8c00}.btn_game[data-v-784ebc0f]:after{right:0;top:.4em}.btn_game_en[data-v-784ebc0f]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.btn_game_ru[data-v-784ebc0f]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.aspect_ratio_box:not(.device_mobile) .btn_game[data-v-784ebc0f]:after{right:2.6em;top:2em}.aspect_ratio_box:not(.device_mobile) .btn_game[data-v-784ebc0f]:before{left:1em;top:0}.aspect_ratio_box:not(.device_mobile) .btn_timer[data-v-784ebc0f]{font-size:2.2em;left:auto;right:4em;top:0}@keyframes anim-784ebc0f{0%{transform:scale(1)}50%{transform:scale(1.05)}to{transform:scale(1)}}@keyframes anim_hide-784ebc0f{to{transform:scale(1)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
