import { render, staticRenderFns } from "./AppAdvicer.vue?vue&type=template&id=0c8e2c5d&scoped=true"
import script from "./AppAdvicer.vue?vue&type=script&lang=ts"
export * from "./AppAdvicer.vue?vue&type=script&lang=ts"
import style0 from "./AppAdvicer.vue?vue&type=style&index=0&id=0c8e2c5d&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c8e2c5d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonRed: require('/artifacts/BGFrontend/components/UiCustom/Buttons/ButtonRed.vue').default,Window: require('/artifacts/BGFrontend/components/Window.vue').default})
