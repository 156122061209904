import { render, staticRenderFns } from "./Interface.vue?vue&type=template&id=2693e174&scoped=true"
import script from "./Interface.vue?vue&type=script&lang=ts"
export * from "./Interface.vue?vue&type=script&lang=ts"
import style0 from "./Interface.vue?vue&type=style&index=0&id=2693e174&prod&lang=stylus&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2693e174",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ButtonBack: require('/artifacts/BGFrontend/components/Layout/Buttons/ButtonBack.vue').default,TopBar: require('/artifacts/BGFrontend/components/Layout/TopBar.vue').default,ButtonGear: require('/artifacts/BGFrontend/components/Layout/Buttons/ButtonGear.vue').default,ButtonGame: require('/artifacts/BGFrontend/components/Layout/Buttons/ButtonGame.vue').default,ButtonShop: require('/artifacts/BGFrontend/components/Layout/Buttons/ButtonShop.vue').default,ButtonHelp: require('/artifacts/BGFrontend/components/Layout/Buttons/ButtonHelp.vue').default,ButtonTops: require('/artifacts/BGFrontend/components/Layout/Buttons/ButtonTops.vue').default,ButtonExit: require('/artifacts/BGFrontend/components/Layout/Buttons/ButtonExit.vue').default,LanguageSelector: require('/artifacts/BGFrontend/components/Layout/LanguageSelector.vue').default})
