

import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { isMobile } from 'mobile-device-detect';

@Component({    
})
export default class AspectRatio extends Vue {
    isMobile:boolean = isMobile
}
