
import {mapState} from "vuex"

import { Vue, Component, Prop , Watch} from 'vue-property-decorator'
const LOADING_THRESHOLD = 1000;
const MINIMAL_VISIBLE_TIME = 1000;

@Component({
    computed:{
        ...mapState( {
            loading: "loading",
            online: "online",
            pending: "pending",
            gameLoad: "gameLoad",
            authPending: "authPending",
            sendMessage: "sendMessage"
        })
    }    
})
export default class Loading extends Vue {
    declare loading:boolean
    declare online:boolean
    declare pending:boolean
    declare gameLoad:boolean
    declare authPending:boolean
    declare sendMessage:boolean

    minimalShowActive:boolean = false  // we dont want loader showing less then 1 second 
    isVisible:boolean = false 
    timerShow:NodeJS.Timeout | null = null
    timerHide:NodeJS.Timeout | null = null
    
    timerShowClear() {
        if(!this.timerShow) 
            return; 
        clearTimeout(this.timerShow)
        this.timerShow = null;            
    }

    timerHideClear() {
        if(!this.timerHide) 
            return; 
        clearTimeout(this.timerHide)
        this.timerHide = null;            
    }

    timerShowSet( fun: ()=>void, interval:number) {            
        this.timerShowClear();                        
        this.timerShow = setTimeout(()=>{
            this.timerShow = null;
            fun();
        }, interval)
    }

    timerHideSet( fun: ()=>void, interval:number) {            
        this.timerHideClear();                        
        this.timerHide = setTimeout(()=>{
            this.timerHide = null;
            fun();
        }, interval)
    }

    beforeDestroy() {
        this.timerShowClear();
        this.timerHideClear();
        this.stopDebug();
    }

    get showLoader() {
        return this.loading || this.pending || !this.online || this.gameLoad || this.minimalShowActive || this.authPending || this.sendMessage
    }

    created() {
        this.updateVisible(this.showLoader, 0);
    }

    timerDebug: NodeJS.Timeout| null = null
    startDebug() {
        this.stopDebug();
        let doDebugPrintout = ()=>{
            console.debug("Debug loading","loading",this.loading, "pending",this.pending,"offline",!this.online,"gameLoad", this.gameLoad,"minimalShowActive",this.minimalShowActive , "authPending", this.authPending)
        }    
        this.timerDebug = setInterval(doDebugPrintout,500)
        doDebugPrintout();
    }
    stopDebug() {
        if(!this.timerDebug)
            return;
        clearInterval(this.timerDebug)
        this.timerDebug = null;
    }

    showStart() {
        this.isVisible = true;
        this.minimalShowActive = true;
        console.log("minimal start");
        this.timerHideSet( ()=>{
            this.minimalShowActive = false;                    
            console.log("minimal end");
        }, MINIMAL_VISIBLE_TIME);  
        
        this.startDebug();
    }

    showStop() {
        this.timerShowClear();
        this.timerHideClear();
        this.isVisible = false;        
        this.stopDebug();
    }

    updateVisible(value:boolean, threshold:number) {
         if(value) {
            this.timerShowSet( ()=>{
                this.showStart()                
            }, threshold);            
        } else {
            this.showStop();
        }
    }

    @Watch("showLoader")
    onShowLoaderChanged(value:boolean) {
       this.updateVisible(value, LOADING_THRESHOLD);       
    }

}
