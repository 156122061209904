// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/ui/layout/tops.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/images/ui/layout/tops_ru.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".btn_tops[data-v-2a9b49d4]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");display:block;overflow:visible;position:relative}.btn_tops_ru[data-v-2a9b49d4]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.btn_tops[data-v-2a9b49d4]:after{right:-.2em;top:-.4em}.aspect_ratio_box:not(.device_mobile) .btn_tops[data-v-2a9b49d4]:after{right:.5em;top:.5em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
